import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { List, makeStyles } from '@material-ui/core';

import Attachment from 'pages/PageRMSIncidents/Accordions/Attachment';

import WarrantDetailsBar from './WarrantDetailsBar';
import WarrantOffenses from './WarrantOffenses';
import Parties from './Parties';
import History from './History';
import WarrantInformation from './WarrantInformation';
import WarrantNotes from './WarrantNotes';
import AffidavitsText from './AffidavitsText';
import Dialog from 'components/RMSDialog';
import RMSConfirmationModal from 'components/RMSModals/RMSConfirmationModal';

import { getTitleForDialog } from 'global-components/ReadOnlyText';
import { getDataOfDictionaryOptions, addUpdatedBy, deepEqual } from 'utils/functions';

import { closeEditWarrant } from 'reducers/PersonDialogsReducer';
import { getService } from 'reducers/service';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(1),
  },
  bar: {
    margin: -theme.spacing(1),
  },
  gridBg: {
    width: '98%',
    margin: '15px 0px 15px 0px',
    border: '2px solid',
    borderColor: theme.palette.colors.borderColor,
    display: 'block',
    height: '155px',
    paddingTop: '2px',
  },
  fieldsetTitle: {
    position: 'absolute',
    marginTop: '3px',
    zIndex: 9999,
    color: 'white',
    background: theme.palette.colors.chipBg,
    borderRadius: '10px',
    padding: '2px 10px',
    marginLeft: '10px',
  },
}));

function EditWarrantDialog(props) {
  const { data } = props;
  const classes = useStyles();

  const [oldObj, setOldObj] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [warrant, setWarrant] = useState({
    FullName: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Suffix: '',
    Prefix: '',
    NickName: '',
    DOB: null,
    ptsPersonID: data.ptsPersonID,
    WarrantType: '',
    HeldByID: null,
    IssuedDate: null,
    ptsStatuteID: null,
    IssueAgencyID: '',
    ptsIncidentID: null,
    IncidentID: '',
    Court: '',
    BondDate: null,
    IsBondAllowed: false,
    BondAmount: '',
    BondType: '',
    AmountPaid: '',
    DispositionDate: null,
    FineAmount: '',
    WarrantClass: '',
    ArrestDate: null,
    DocketNumber: '',
    IsOnHold: false,
    OffenseDate: null,
    CountQty: null,
    ServedByDate: null,
    AffidavitText: '',
    Notes: '',
    ExpirationDate: null,
    IsDocketCreated: false,
    IsClearedByArrestDocketNotCreated: false,
    Zone: '',
    DispositionIntCode: null,
    UserWarrantID: '',
    ptsWarrantID: null,
    WarrantID: '',
    LegacyConversionNotes: '',
    PrintLabel: '',
    CourtDate: null,
    DispositionCode: '',
    IsActive: true,
  });

  const dataUpdate = useSelector((state) => state.dataUpdate);

  const service = getService('rms-warrant');
  const warrantPermission = getFullPermissions('globals', 'Warrants', 'any');

  useEffect(() => {
    if (data.ptsWarrantID || (dataUpdate && dataUpdate.type === 'single-warrant')) {
      service.get(data.ptsWarrantID).then((res) => {
        const responseItem = res[0];

        // Now, set the state with the updated object
        setWarrant({ ...responseItem, ptsPersonID: data.ptsPersonID });
        setOldObj({ ...responseItem, ptsPersonID: data.ptsPersonID });
      });
    }
  }, [dataUpdate]);

  const onClickCancel = () => {
    setOpenConfirmation(false);
  };

  const onClickNo = () => {
    setOpenConfirmation(false);
    props.closeEditWarrant();
  };

  const handleUnsavedData = () => {
    save();
    setOpenConfirmation(false);
    props.closeEditWarrant();
  };

  const onChange = (key = '', value = '') => {
    if (key) {
      setWarrant((prevWarrant) => ({ ...prevWarrant, [key]: value }));
    }
  };

  const close = () => {
    const isEqual = deepEqual(oldObj, warrant);
    if (!isEqual && warrantPermission.Edit) {
      setOpenConfirmation(true);
      return;
    }
    props.closeEditWarrant();
  };

  const save = () => {
    const updateData = addUpdatedBy(warrant);

    if (updateData?.DispositionIntCode && updateData.DispositionCode === undefined)
      updateData.DispositionCode = getDataOfDictionaryOptions(
        'codeWarrantDispositions',
        'IntCode',
        'Code',
        updateData.DispositionIntCode
      );

    service.patch(warrant.ptsWarrantID, { type: 'warrant', data: updateData }).then((res) => {
      setOldObj(warrant);
      props.notifyDataUpdate({
        type: 'Warrant',
        data: {},
      });
    });
  };

  return (
    <>
      <Dialog
        toolbar
        onClose={close}
        title={getTitleForDialog(warrantPermission, 'Edit Warrant')}
        fullScreen>
        <div className={classes.bar}>
          <WarrantDetailsBar
            warrant={warrant}
            onChange={onChange}
            onClose={close}
            onSave={save}
            viewOnly={!warrantPermission.Edit}
          />
        </div>
        <div className={classes.content}>
          <List>
            <WarrantOffenses
              ptsWarrantID={data.ptsWarrantID}
              defaultOffense={warrant.ptsStatuteID}
              viewOnly={!warrantPermission.Edit}
            />
            <WarrantInformation
              warrant={warrant}
              onChange={onChange}
              viewOnly={!warrantPermission.Edit}
            />
            <Parties ptsWarrantID={data.ptsWarrantID} viewOnly={!warrantPermission.Edit} />
            <WarrantNotes
              warrant={warrant}
              onChange={onChange}
              viewOnly={!warrantPermission.Edit}
            />
            <Attachment
              sectionTitle={'Attachments'}
              expandList={false}
              warrantId={data.ptsWarrantID}
              attachmentType={'Warrant'}
            />
            <AffidavitsText warrant={warrant} onChange={onChange} />
            <History ptsWarrantID={data.ptsWarrantID} />
          </List>
        </div>
      </Dialog>
      <RMSConfirmationModal
        title="You have unsaved changes in this form!"
        text="Do you want to save changes?"
        saveBtnName="Yes"
        btnName="No"
        noBtnName="Cancel"
        open={openConfirmation}
        closeDialog={onClickCancel}
        handleSubmit={onClickNo}
        handleModalSave={handleUnsavedData}
      />
    </>
  );
}

export default connect(null, { closeEditWarrant, notifyDataUpdate })(EditWarrantDialog);
