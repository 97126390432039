import store from '../../config/configureStore';

import { setActiveIncidents } from 'reducers/IncReducer';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { formatSaveDateFrom, formatSaveDateTo } from 'reducers/TimeReducer';
import { prepareIncidentAttachmentsData } from 'reducers/helpers/uiHomeHelper';

export const SET_INCIDENT_LIST_CURRENT_VIEW = 'SET_INCIDENT_LIST_CURRENT_VIEW';
export const SET_TOTAL_ACTIVE_INCIDENTS = 'SET_TOTAL_ACTIVE_INCIDENTS';
export const SET_ACTIVE_INCIDENTS = 'SET_ACTIVE_INCIDENTS';
export const SET_EXPAND_OR_NOT_VALUE = 'SET_EXPAND_OR_NOT_VALUE';
export const SET_OCCURED_FROM_DATE = 'SET_OCCURED_FROM_DATE';
export const SET_OCCURED_TO_DATE = 'SET_OCCURED_TO_DATE';
export const SET_OCCURRED_TIME_PERIOD_VALUE = 'SET_OCCURRED_TIME_PERIOD_VALUE';
export const SET_RECEIVED_TIME_PERIOD_VALUE = 'SET_RECEIVED_TIME_PERIOD_VALUE';
export const SET_RECEIVED_FROM_DATE = 'SET_RECEIVED_FROM_DATE';
export const SET_RECEIVED_TO_DATE = 'SET_RECEIVED_TO_DATE';
export const SET_QUICK_FILTER_VALUE = 'SET_QUICK_FILTER_VALUE';
export const SET_SHARE_FILTER_VALUE = 'SET_SHARE_FILTER_VALUE';
export const SET_ROUTE_FILTER_VALUE = 'SET_ROUTE_FILTER_VALUE';
export const SET_GROUP_BY_VALUE = 'SET_GROUP_BY_VALUE';
export const SET_SORT_BY_VALUE = 'SET_SORT_BY_VALUE';
export const SET_SORT_BY_DIRECTION = 'SET_SORT_BY_DIRECTION';
export const SET_FILTER_FORM_VALUES = 'SET_FILTER_FORM_VALUES';
export const SET_FILTER_ALL_VALUES = 'SET_FILTER_ALL_VALUES';
export const SET_ROW_FILTER_MODEL = 'SET_ROW_FILTER_MODEL';
export const SET_COLUMN_VISIBLE_MODEL = 'SET_COLUMN_VISIBLE_MODEL';
export const SET_PINNED_COLUMNS = 'SET_PINNED_COLUMNS';
export const SET_SORTING_MODELS = 'SET_SORTING_MODELS';
export const SET_GROUP_FILTER_KEY = 'SET_GROUP_FILTER_KEY';
export const SET_INCIDENT_COLUMN_ORDER = 'SET_INCIDENT_COLUMN_ORDER';
export const SET_ACTIVE_WORKFLOW = 'SET_ACTIVE_WORKFLOW';
export const SET_ACTIVE_WORKFLOW_INCIDENTS = 'SET_ACTIVE_WORKFLOW_INCIDENTS';
export const RESET_INCIDENT_FILTER_VALUES = 'RESET_INCIDENT_FILTER_VALUES';
export const SET_WORKFLOW_EXPANSION_VALUES = 'SET_WORKFLOW_EXPANSION_VALUES';
export const CLEAR_ALL_INCIDENT_FILTERS = 'CLEAR_ALL_INCIDENT_FILTERS';
export const SET_EXPORTABLE_INCIDENTS = 'SET_EXPORTABLE_INCIDENTS';

export const getAllActiveIncidents = (user, page, rowsPerPage) => {
  const client = store.store.getState().websocket.websocket;
  const incidentFilter = store.store.getState().uiHomeScreen.incidentFilter;
  const agencyID = store.store.getState().user.userAgency;
  const userCurrentClearanceLevel = store.store.getState().user.userCurrentClearanceLevel;

  const permissions = store.store.getState().permissions;
  const quickSearch =
    incidentFilter.quickFilter && incidentFilter.quickFilter.length > 2
      ? incidentFilter.quickFilter
      : null;
  let canSeeJuvenileReport = false;
  if (Object.keys(permissions.rms).length !== 0) {
    canSeeJuvenileReport = getAccessPermission('rms', 'Can see juvenile report', agencyID);
  }

  return async (dispatch) => {
    try {
      if (incidentFilter.incidentListCurrentView === 'incidentCardView') {
        const service = client.service('rms-incidents-v-2');
        service.timeout = 50000;
        // console.log('filtering date', formatSaveDateFrom(incidentFilter?.occuredFrom));
        service
          .find({
            query: {
              page,
              rowsPerPage: rowsPerPage,
              clearanceLevel: parseInt(userCurrentClearanceLevel.Level) || 0,
              occuredFrom: incidentFilter?.occuredFrom
                ? formatSaveDateFrom(incidentFilter?.occuredFrom)
                : null,
              occuredTo: incidentFilter?.occuredTo
                ? formatSaveDateTo(incidentFilter?.occuredTo)
                : null,
              receivedFrom: incidentFilter?.receivedFrom
                ? formatSaveDateFrom(incidentFilter?.receivedFrom)
                : null,
              receivedTo: incidentFilter?.receivedTo
                ? formatSaveDateTo(incidentFilter?.receivedTo)
                : null,
              sortBy: incidentFilter?.sortBy,
              sortByDirection: incidentFilter?.sortByDirection,
              partyName: incidentFilter?.filterForm?.partyName?.FullName || null,
              partyType: incidentFilter?.filterForm?.partyType || null,
              ptsCityID: incidentFilter?.filterForm?.ptsCityID || null,
              ptsAddressID: incidentFilter?.filterForm?.ptsAddressID || null,
              streetName: incidentFilter?.filterForm?.streetName || null,
              streetType: incidentFilter?.filterForm?.streetType || null,
              predirectional: incidentFilter?.filterForm?.predirectional || null,
              postdirectional: incidentFilter?.filterForm?.postdirectional || null,
              unitType: incidentFilter?.filterForm?.unitType || null,
              unitNumber: incidentFilter?.filterForm?.unitNumber || null,
              lastName: incidentFilter?.filterForm?.lastName || null,
              firstName: incidentFilter?.filterForm?.firstName || null,
              middleName: incidentFilter?.filterForm?.middleName || null,
              zone: incidentFilter?.filterForm?.zone || null,
              cadEventNumber: incidentFilter?.filterForm?.cadEventNumber || null,
              quickFilter: quickSearch || null,
              ptsUserIdShareFilter: incidentFilter?.ptsUserIdShareFilter || null,
              ptsUserIdRouteFilter: incidentFilter?.ptsUserIdRouteFilter || null,
              status: incidentFilter?.filterForm?.status || null,
              tagType: incidentFilter?.filterForm?.tagType || null,
              tagValue: incidentFilter?.filterForm?.tagValue || null,
              offense: incidentFilter?.filterForm?.offense || null,
              disposition: incidentFilter?.filterForm?.disposition || null,
              lowBlock: incidentFilter?.filterForm?.lowBlock || null,
              highBlock: incidentFilter?.filterForm?.highBlock || null,
              contactMethod: incidentFilter?.filterForm?.contactMethod || null,
              contactInfo: incidentFilter?.filterForm?.contactInfo || null,
              agencyId: agencyID,
            },
          })
          .then((result) => {
            let activeList = [];
            let totalIncidents = 0;

            result?.map((r) => {
              if (!canSeeJuvenileReport && r.IsJuvenileReport) {
                return;
              }

              const { HiddenOffenses, HiddenParties, HiddenProperties } = r;

              const hiddenData = HiddenOffenses || HiddenParties || HiddenProperties ? true : false;

              const parties = r.Parties ? JSON.parse(r.Parties) : [];
              const offenses = r.Offenses ? JSON.parse(r.Offenses) : [];
              const properties = r.Properties ? JSON.parse(r.Properties) : [];
              const narratives = r.Narratives ? JSON.parse(r.Narratives) : [];
              const evidences = r.Evidences ? JSON.parse(r.Evidences) : [];
              const incAttachments = r.Attachments ? JSON.parse(r.Attachments) : [];

              /* Sync the dvVictim data with the parties for Violence report print  -> Start*/
              let dvVictimsList = [];
              offenses.map((o) => {
                if (o.Victims && o.Victims.length > 0) {
                  o.Victims.map((v) => {
                    if (v.DVVictim && v.DVVictim.length > 0) {
                      v.DVVictim.forEach((dv) => dvVictimsList.push(dv));
                    }
                  });
                }
              });

              if (dvVictimsList.length > 0) {
                dvVictimsList.forEach((dv) => {
                  let partyId = dv.ptsIncPersonID;

                  parties.forEach((p, i) => {
                    if (p.PartyID === partyId) {
                      parties[i] = {
                        ...p,
                        isDVVictim: true,
                        ptsVictimID: dv.ptsVictimID,
                      };
                    } else {
                      parties[i] = {
                        ...p,
                        isDVVictim: false,
                      };
                    }
                  });
                });
              }
              /* -> End*/

              /* Sync the arrestee data with the parties for Arrest report print  -> Start*/
              let arresteesList = [];
              offenses.map((o) => {
                if (o.Arrestees && o.Arrestees.length > 0) {
                  o.Arrestees.map((a) => arresteesList.push(a));
                }
              });

              if (arresteesList.length > 0) {
                arresteesList.forEach((ar) => {
                  let partyId = ar.ptsIncPersonID;

                  parties.forEach((p, i) => {
                    if (p.PartyID === partyId) {
                      parties[i] = {
                        ...p,
                        isArrestee: true,
                        ptsArresteeID: ar.ptsArresteeID,
                      };
                    }
                  });
                });
              }
              /* -> End*/

              // Prepare incident attachments data
              let allAttachments = prepareIncidentAttachmentsData(
                incAttachments,
                parties,
                properties,
                narratives,
                evidences
              );

              activeList.push({
                agencyID: r.AgencyID,
                callType: r.CallType,
                completed: r.Completed,
                cancelled: r.Cancelled,
                dispatched: r.Dispatched,
                createdBy: r.CreatedBy,
                occurred: r.Occurred,
                updated: r.Updated,
                updatedBy: r.UpdatedBy,
                received: r.Received,
                isLocked: r.IsLocked,
                enroute: r.Enroute,
                primaryAddress: r.FullAddress,
                eventType: r.CallType,
                incidentId: r.ptsIncidentID,
                CustomId: r.IncidentID,
                ptsIncidentId: r.ptsIncidentID,
                parties: parties,
                properties: properties,
                offenses: offenses,
                statuses: r.Statuses ? JSON.parse(r.Statuses) : [],
                synopsis: r.Synopsis ? r.Synopsis : '',
                tags: r.Tags ? JSON.parse(r.Tags) : [],
                nibrsErrors: r.IBRS ? JSON.parse(r.IBRS) : [],
                narratives: narratives,
                evidences: evidences,
                attachments: allAttachments,
                hiddenData: hiddenData,
                hiddenOffenses: HiddenOffenses ? true : false,
                IsJuvenileReport: r.IsJuvenileReport,
              });
              totalIncidents = r.Count;
            });

            dispatch({ type: SET_ACTIVE_INCIDENTS, payload: activeList });
            dispatch({ type: SET_TOTAL_ACTIVE_INCIDENTS, payload: totalIncidents });
            dispatch(setActiveIncidents(activeList));
            dispatch(setExportableIncidents(activeList));
          });
      } else {
        const service = client.service('rms-incidents-v-2-grid-view');
        service.timeout = 50000;
        service
          .find({
            query: {
              page,
              rowsPerPage: rowsPerPage,
              userClearanceLevel: parseInt(userCurrentClearanceLevel.Level) || 0,
              occuredFrom: incidentFilter?.occuredFrom
                ? formatSaveDateFrom(incidentFilter?.occuredFrom)
                : null,
              occuredTo: incidentFilter?.occuredTo
                ? formatSaveDateTo(incidentFilter?.occuredTo)
                : null,
              receivedFrom: incidentFilter?.receivedFrom
                ? formatSaveDateFrom(incidentFilter?.receivedFrom)
                : null,
              receivedTo: incidentFilter?.receivedTo
                ? formatSaveDateTo(incidentFilter?.receivedTo)
                : null,
              sortBy: incidentFilter?.sortBy,
              sortByDirection: incidentFilter?.sortByDirection,
              partyName: incidentFilter?.filterForm?.partyName?.FullName || null,
              partyType: incidentFilter?.filterForm?.partyType || null,
              ptsCityID: incidentFilter?.filterForm?.ptsCityID || null,
              ptsAddressID: incidentFilter?.filterForm?.ptsAddressID || null,
              streetName: incidentFilter?.filterForm?.streetName || null,
              streetType: incidentFilter?.filterForm?.streetType || null,
              predirectional: incidentFilter?.filterForm?.predirectional || null,
              postdirectional: incidentFilter?.filterForm?.postdirectional || null,
              unitType: incidentFilter?.filterForm?.unitType || null,
              unitNumber: incidentFilter?.filterForm?.unitNumber || null,
              lastName: incidentFilter?.filterForm?.lastName || null,
              firstName: incidentFilter?.filterForm?.firstName || null,
              middleName: incidentFilter?.filterForm?.middleName || null,
              zone: incidentFilter?.filterForm?.zone || null,
              cadEventNumber: incidentFilter?.filterForm?.cadEventNumber || null,
              quickFilter: quickSearch || null,
              ptsUserIdShareFilter: incidentFilter?.ptsUserIdShareFilter || null,
              ptsUserIdRouteFilter: incidentFilter?.ptsUserIdRouteFilter || null,
              status: incidentFilter?.filterForm?.status || null,
              tagType: incidentFilter?.filterForm?.tagType || null,
              tagValue: incidentFilter?.filterForm?.tagValue || null,
              offense: incidentFilter?.filterForm?.offense || null,
              disposition: incidentFilter?.filterForm?.disposition || null,
              lowBlock: incidentFilter?.filterForm?.lowBlock || null,
              highBlock: incidentFilter?.filterForm?.highBlock || null,
              contactMethod: incidentFilter?.filterForm?.contactMethod || null,
              contactInfo: incidentFilter?.filterForm?.contactInfo || null,
              agencyId: agencyID,
            },
          })
          .then((result) => {
            let activeList = [];
            let totalIncidents = 0;

            result?.map((r) => {
              if (!canSeeJuvenileReport && r.IsJuvenileReport) {
                return;
              }
              const { HiddenOffenses, HiddenParties, HiddenProperties } = r;

              const hiddenData = HiddenOffenses || HiddenParties || HiddenProperties ? true : false;

              const parties = r.Parties ? JSON.parse(r.Parties) : [];
              const offenses = r.Offenses ? JSON.parse(r.Offenses) : [];
              const properties = r.Properties ? JSON.parse(r.Properties) : [];
              const narratives = r.Narratives ? JSON.parse(r.Narratives) : [];
              const evidences = r.Evidences ? JSON.parse(r.Evidences) : [];
              const incAttachments = r.Attachments ? JSON.parse(r.Attachments) : [];

              const assignOfficer = parties.filter(
                (party) => party.RelationshipCategory === 'ASGNOFF'
              );
              const len = assignOfficer.length;
              const assignedOfficersString = assignOfficer.reduce((str, curr, index) => {
                str += curr.PartyName;
                if (index < len - 1) str += ', ';
                return str;
              }, '');

              /* Sync the dvVictim data with the parties for Violence report print  -> Start*/
              let dvVictimsList = [];
              offenses.map((o) => {
                if (o.Victims && o.Victims.length > 0) {
                  o.Victims.map((v) => {
                    if (v.DVVictim && v.DVVictim.length > 0) {
                      v.DVVictim.forEach((dv) => dvVictimsList.push(dv));
                    }
                  });
                }
              });

              if (dvVictimsList.length > 0) {
                dvVictimsList.forEach((dv) => {
                  let partyId = dv.ptsIncPersonID;

                  parties.forEach((p, i) => {
                    if (p.PartyID === partyId) {
                      parties[i] = {
                        ...p,
                        isDVVictim: true,
                        ptsVictimID: dv.ptsVictimID,
                      };
                    } else {
                      parties[i] = {
                        ...p,
                        isDVVictim: false,
                      };
                    }
                  });
                });
              }
              /* -> End*/

              /* Sync the arrestee data with the parties for Arrest report print  -> Start*/
              let arresteesList = [];
              offenses.map((o) => {
                if (o.Arrestees && o.Arrestees.length > 0) {
                  o.Arrestees.map((a) => arresteesList.push(a));
                }
              });

              if (arresteesList.length > 0) {
                arresteesList.forEach((ar) => {
                  let partyId = ar.ptsIncPersonID;

                  parties.forEach((p, i) => {
                    if (p.PartyID === partyId) {
                      parties[i] = {
                        ...p,
                        isArrestee: true,
                        ptsArresteeID: ar.ptsArresteeID,
                      };
                    }
                  });
                });
              }
              /* -> End*/

              // Prepare incident attachments data
              let allAttachments = prepareIncidentAttachmentsData(
                incAttachments,
                parties,
                properties,
                narratives,
                evidences
              );

              activeList.push({
                agencyID: r.AgencyID,
                callType: r.CallType,
                completed: r.Completed,
                cancelled: r.Cancelled,
                dispatched: r.Dispatched,
                createdBy: r.CreatedBy,
                occurred: r.Occurred,
                updated: r.Updated,
                updatedBy: r.UpdatedBy,
                received: r.Received,
                isLocked: r.IsLocked,
                enroute: r.Enroute,
                primaryAddress: r.FullAddress,
                eventType: r.CallType,
                incidentId: r.ptsIncidentID,
                CustomId: r.IncidentID,
                ptsIncidentId: r.ptsIncidentID,
                parties: parties,
                properties: properties,
                offenses: offenses,
                statuses: r.Statuses ? JSON.parse(r.Statuses) : [],
                synopsis: r.Synopsis ? r.Synopsis : '',
                tags: r.Tags ? JSON.parse(r.Tags) : [],
                zones: r.Zones ? JSON.parse(r.Zones) : [],
                nibrsErrors: r.IBRS ? JSON.parse(r.IBRS) : [],
                hiddenData: hiddenData,
                hiddenOffenses: HiddenOffenses ? true : false,
                narratives: narratives,
                evidences: evidences,
                attachments: allAttachments,
                IsJuvenileReport: r.IsJuvenileReport,
                assignOfficer: assignedOfficersString || '',
              });
              totalIncidents = r.Count;
            });

            dispatch({ type: SET_ACTIVE_INCIDENTS, payload: activeList });
            dispatch({ type: SET_TOTAL_ACTIVE_INCIDENTS, payload: totalIncidents });
            dispatch(setActiveIncidents(activeList));
            dispatch(setExportableIncidents(activeList));
          });
      }
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getAllActiveIncidentsForWorkflow = () => {
  const client = store.store.getState().websocket.websocket;
  const uiHomeScreen = store.store.getState().uiHomeScreen;
  const { incidentFilter } = uiHomeScreen;
  const agencyID = store.store.getState().user.userAgency;
  const userCurrentClearanceLevel = store.store.getState().user.userCurrentClearanceLevel;

  const permissions = store.store.getState().permissions;

  let canSeeJuvenileReport = false;
  if (Object.keys(permissions.rms).length !== 0) {
    canSeeJuvenileReport = getAccessPermission('rms', 'Can see juvenile report', agencyID);
  }

  return async (dispatch) => {
    try {
      const service = client.service('rms-incidents-fetch-workflow');
      service.timeout = 50000;
      service
        .find({
          query: {
            userClearanceLevel: parseInt(userCurrentClearanceLevel.Level) || 0,
            ptsWFlowId: incidentFilter.activeWorkflow,
            occuredFrom: incidentFilter?.occuredFrom || null,
            occuredTo: incidentFilter?.occuredTo || null,
            receivedFrom: incidentFilter?.receivedFrom || null,
            receivedTo: incidentFilter?.receivedTo || null,
            partyName: incidentFilter?.filterForm?.partyName?.FullName || null,
            partyType: incidentFilter?.filterForm?.partyType || null,
            ptsCityID: incidentFilter?.filterForm?.ptsCityID || null,
            ptsAddressID: incidentFilter?.filterForm?.ptsAddressID || null,
            streetName: incidentFilter?.filterForm?.streetName || null,
            streetType: incidentFilter?.filterForm?.streetType || null,
            predirectional: incidentFilter?.filterForm?.predirectional || null,
            postdirectional: incidentFilter?.filterForm?.postdirectional || null,
            unitType: incidentFilter?.filterForm?.unitType || null,
            unitNumber: incidentFilter?.filterForm?.unitNumber || null,
            lastName: incidentFilter?.filterForm?.lastName || null,
            firstName: incidentFilter?.filterForm?.firstName || null,
            middleName: incidentFilter?.filterForm?.middleName || null,
            zone: incidentFilter?.filterForm?.zone || null,
            cadEventNumber: incidentFilter?.filterForm?.cadEventNumber || null,
            quickFilter: incidentFilter?.quickFilter || null,
            ptsUserIdShareFilter: incidentFilter?.ptsUserIdShareFilter || null,
            ptsUserIdRouteFilter: incidentFilter?.ptsUserIdRouteFilter || null,
            status: incidentFilter?.filterForm?.status || null,
            tagType: incidentFilter?.filterForm?.tagType || null,
            tagValue: incidentFilter?.filterForm?.tagValue || null,
            offense: incidentFilter?.filterForm?.offense || null,
            disposition: incidentFilter?.filterForm?.disposition || null,
            lowBlock: incidentFilter?.filterForm?.lowBlock || null,
            highBlock: incidentFilter?.filterForm?.highBlock || null,
            contactMethod: incidentFilter?.filterForm?.contactMethod || null,
            contactInfo: incidentFilter?.filterForm?.contactInfo || null,
            agencyId: agencyID,
            sortBy: incidentFilter.sortBy,
            sortByDirection: incidentFilter.sortByDirection,
          },
        })
        .then((result) => {
          const res = result.map((r) => {
            if (!canSeeJuvenileReport && r.IsJuvenileReport) {
              return;
            }
            return r;
          });

          dispatch({ type: SET_ACTIVE_WORKFLOW_INCIDENTS, payload: res });
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const setActiveWorkflow = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_WORKFLOW, payload: value });
  };
};

export const setIncidentListCurrentView = (view) => {
  return (dispatch) => {
    dispatch({ type: SET_INCIDENT_LIST_CURRENT_VIEW, payload: view });
  };
};

export const setIncidentColumnOrder = (columnOrder) => {
  return (dispatch) => {
    dispatch({ type: SET_INCIDENT_COLUMN_ORDER, payload: columnOrder });
  };
};

export const setExpandOrNotValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EXPAND_OR_NOT_VALUE, payload: value });
  };
};

export const setOccuredFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_OCCURED_FROM_DATE, payload: date });
  };
};

export const setOccuredToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_OCCURED_TO_DATE, payload: date });
  };
};

export const setReceivedFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_RECEIVED_FROM_DATE, payload: date });
  };
};

export const setReceivedToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_RECEIVED_TO_DATE, payload: date });
  };
};

export const setOccurredTimePeriodValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_OCCURRED_TIME_PERIOD_VALUE, payload: value });
  };
};

export const setReceivedTimePeriodValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_RECEIVED_TIME_PERIOD_VALUE, payload: value });
  };
};

export const setQuickFilterValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_QUICK_FILTER_VALUE, payload: value });
  };
};

export const setShareFilterValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_SHARE_FILTER_VALUE, payload: value });
  };
};

export const setRouteFilterValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_ROUTE_FILTER_VALUE, payload: value });
  };
};

export const setGroupByValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_GROUP_BY_VALUE, payload: value });
  };
};

export const setSortByValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_SORT_BY_VALUE, payload: value });
  };
};

export const setSortByDirection = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_SORT_BY_DIRECTION, payload: value });
  };
};

export const setFilterFormValues = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_FILTER_FORM_VALUES, payload: value });
  };
};

export const setFilterAllValues = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_FILTER_ALL_VALUES, payload: value });
  };
};

export const resetIncidentFilterForm = () => {
  let filterForm = {
    placeOrAddress: '',
    lowBlock: '',
    highBlock: '',
    predirectional: '',
    streetName: '',
    postdirectional: '',
    streetType: '',
    unitType: '',
    unitNumber: '',
    city: '',
    zone: '',
    partyType: '',
    partyName: '',
    lastName: '',
    firstName: '',
    middleName: '',
    contactMethod: '',
    contactInfo: '',
    cadEventNumber: '',
    status: '',
    tagType: '',
    tagValue: '',
    offense: '',
    disposition: '',
  };

  return (dispatch) => {
    dispatch({ type: RESET_INCIDENT_FILTER_VALUES, payload: filterForm });
  };
};

export const setRowFilterModel = (model) => {
  return (dispatch) => {
    dispatch({ type: SET_ROW_FILTER_MODEL, payload: model });
  };
};

export const setColumnVisibleModel = (model) => {
  return (dispatch) => {
    dispatch({ type: SET_COLUMN_VISIBLE_MODEL, payload: model });
  };
};

export const setPinnedColumns = (pinnedValue) => {
  return (dispatch) => {
    dispatch({ type: SET_PINNED_COLUMNS, payload: pinnedValue });
  };
};

export const setSortingModels = (sortingModel) => {
  return (dispatch) => {
    dispatch({ type: SET_SORTING_MODELS, payload: sortingModel });
  };
};

export const setGroupFilterKey = (groupKey) => {
  return (dispatch) => {
    dispatch({ type: SET_GROUP_FILTER_KEY, payload: groupKey });
  };
};

export const setWorkflowExpansionValues = (expansionValues) => {
  return (dispatch) => {
    dispatch({ type: SET_WORKFLOW_EXPANSION_VALUES, payload: expansionValues });
  };
};

export const setExportableIncidents = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EXPORTABLE_INCIDENTS, payload: value });
  };
};

export const clearAllFilters = () => {
  const filterValue = {
    occuredFrom: null,
    occuredTo: null,
    receivedFrom: null,
    receivedTo: null,
    quickFilter: '',
    ptsUserIdShareFilter: '',
    ptsUserIdRouteFilter: '',
    occurredTimePeriod: 'Custom',
    receivedTimePeriod: 'Custom',
    filterForm: {
      placeOrAddress: '',
      lowBlock: '',
      highBlock: '',
      predirectional: '',
      streetName: '',
      postdirectional: '',
      streetType: '',
      unitType: '',
      unitNumber: '',
      city: '',
      zone: '',
      partyType: '',
      partyName: null,
      lastName: '',
      firstName: '',
      middleName: '',
      contactMethod: '',
      contactInfo: '',
      cadEventNumber: '',
      status: '',
      tagType: '',
      tagValue: '',
      offense: '',
      disposition: '',
    },
  };

  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_INCIDENT_FILTERS, payload: filterValue });
  };
};

export default function reducer(
  state = {
    activeIncidents: null,
    activeWorkflowIncidents: [],
    totalIncidents: 0,
    workflowExpansionValues: [],
    exportableIncidents: [],
    incidentFilter: {
      activeWorkflow: null,
      sortBy: 'Incident Number',
      sortByDirection: 'DESC',
      groupBy: '',
      incidentListCurrentView: 'incidentCardView',
      expandOrNotValue: false,
      occuredFrom: null,
      occuredTo: null,
      occurredTimePeriod: '',
      receivedTimePeriod: '',
      receivedFrom: null,
      receivedTo: null,
      quickFilter: '',
      ptsUserIdShareFilter: '',
      ptsUserIdRouteFilter: '',
      filterForm: {
        placeOrAddress: '',
        lowBlock: '',
        highBlock: '',
        predirectional: '',
        streetName: '',
        postdirectional: '',
        streetType: '',
        unitType: '',
        unitNumber: '',
        city: '',
        zone: '',
        partyType: '',
        partyName: null,
        lastName: '',
        firstName: '',
        middleName: '',
        contactMethod: '',
        contactInfo: '',
        cadEventNumber: '',
        status: '',
        tagType: '',
        tagValue: '',
        offense: '',
        disposition: '',
      },
      gridFilters: {
        rowFilterModel: {
          items: [],
          linkOperator: 'and',
        },
        columnVisibleModel: {
          actions: true,
          address: true,
          agency: true,
          assignedOfficer: true,
          eventType: true,
          incidentNumber: true,
          lastUpdated: true,
          occurredDate: true,
        },
        pinnedColumns: {
          left: [],
          right: [],
        },
        incidentColumnOrder: [],
        sortingModels: [],
        groupFilterKey: '',
      },
    },
  },
  action
) {
  switch (action.type) {
    case SET_INCIDENT_LIST_CURRENT_VIEW:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, incidentListCurrentView: action.payload },
      };
    case SET_TOTAL_ACTIVE_INCIDENTS:
      return {
        ...state,
        totalIncidents: action.payload,
      };
    case SET_ACTIVE_INCIDENTS:
      return {
        ...state,
        activeIncidents: action.payload,
      };
    case SET_EXPAND_OR_NOT_VALUE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, expandOrNotValue: action.payload },
      };
    case SET_OCCURED_FROM_DATE:
      return { ...state, incidentFilter: { ...state.incidentFilter, occuredFrom: action.payload } };
    case SET_OCCURED_TO_DATE:
      return { ...state, incidentFilter: { ...state.incidentFilter, occuredTo: action.payload } };
    case SET_RECEIVED_FROM_DATE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, receivedFrom: action.payload },
      };
    case SET_RECEIVED_TO_DATE:
      return { ...state, incidentFilter: { ...state.incidentFilter, receivedTo: action.payload } };
    case SET_OCCURRED_TIME_PERIOD_VALUE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, occurredTimePeriod: action.payload },
      };
    case SET_RECEIVED_TIME_PERIOD_VALUE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, receivedTimePeriod: action.payload },
      };
    case SET_QUICK_FILTER_VALUE:
      return { ...state, incidentFilter: { ...state.incidentFilter, quickFilter: action.payload } };
    case SET_SHARE_FILTER_VALUE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, ptsUserIdShareFilter: action.payload },
      };
    case SET_ROUTE_FILTER_VALUE:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, ptsUserIdRouteFilter: action.payload },
      };
    case SET_GROUP_BY_VALUE:
      return { ...state, incidentFilter: { ...state.incidentFilter, groupBy: action.payload } };
    case SET_SORT_BY_VALUE:
      return { ...state, incidentFilter: { ...state.incidentFilter, sortBy: action.payload } };
    case SET_SORT_BY_DIRECTION:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, sortByDirection: action.payload },
      };
    case SET_FILTER_FORM_VALUES:
      return {
        ...state,
        incidentFilter: { ...state.incidentFilter, filterForm: { ...action.payload } },
      };
    case SET_FILTER_ALL_VALUES:
      return {
        ...state,
        incidentFilter: { ...action.payload },
      };
    case SET_ROW_FILTER_MODEL:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, rowFilterModel: action.payload },
        },
      };
    case SET_COLUMN_VISIBLE_MODEL:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, columnVisibleModel: action.payload },
        },
      };
    case SET_PINNED_COLUMNS:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, pinnedColumns: action.payload },
        },
      };
    case SET_SORTING_MODELS:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, sortingModels: [...action.payload] },
        },
      };
    case SET_GROUP_FILTER_KEY:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, groupFilterKey: action.payload },
        },
      };
    case SET_INCIDENT_COLUMN_ORDER:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          gridFilters: { ...state.incidentFilter.gridFilters, incidentColumnOrder: action.payload },
        },
      };
    case SET_ACTIVE_WORKFLOW:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          activeWorkflow: action.payload,
        },
      };
    case SET_ACTIVE_WORKFLOW_INCIDENTS:
      return {
        ...state,
        activeWorkflowIncidents: action.payload,
      };
    case RESET_INCIDENT_FILTER_VALUES:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          filterForm: action.payload,
        },
      };
    case CLEAR_ALL_INCIDENT_FILTERS:
      return {
        ...state,
        incidentFilter: {
          ...state.incidentFilter,
          occuredFrom: action.payload.occuredFrom,
          occuredTo: action.payload.occuredTo,
          receivedFrom: action.payload.receivedFrom,
          receivedTo: action.payload.receivedTo,
          quickFilter: action.payload.quickFilter,
          ptsUserIdShareFilter: action.payload.ptsUserIdShareFilter,
          ptsUserIdRouteFilter: action.payload.ptsUserIdRouteFilter,
          occurredTimePeriod: action.payload.occurredTimePeriod,
          receivedTimePeriod: action.payload.receivedTimePeriod,
          filterForm: action.payload.filterForm,
        },
      };
    case SET_WORKFLOW_EXPANSION_VALUES:
      return {
        ...state,
        workflowExpansionValues: [...action.payload],
      };
    case SET_EXPORTABLE_INCIDENTS:
      return {
        ...state,
        exportableIncidents: [...action.payload],
      };
  }

  return state;
}
