import { getCityIdFromCityName } from 'reducers/IncReducer';
import store from '../../config/configureStore';

export const createCit = async (data) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('citcitations');
    service.timeout = 20000000;
    const result = await service.create({
      ...data,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
      UpdatedBy: user,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const patchCit = async (id, data) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('citcitations');
    service.timeout = 20000000;
    const result = await service.patch(id, {
      ...data,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
      UpdatedBy: user,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createCitEntity = async (data, entity) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service(entity);
    service.timeout = 20000;
    const result = await service.create({
      ...data,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
      UpdatedBy: user,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const patchCitEntity = async (id, data, entity) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service(entity);
    service.timeout = 20000;
    const result = await service.patch(id, {
      ...data,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      UpdatedBy: user,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const upsertCitPerson = async (ptsCitationId) => {
  const incidentState = store.store.getState().incident;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const service = client.service('citation-party');
  service.timeout = 100000;

  const {
    partiesPersonForm,
    partiesContactForm,
    partiesEmploymentForm,
    partiesOthersForm,
  } = incidentState;

  if (Object.keys(partiesPersonForm.values).length === 0) return;

  if (typeof partiesContactForm.values?.city === 'string') {
    partiesContactForm.values.cityId = getCityIdFromCityName(partiesContactForm.values?.city);
  }

  if (typeof partiesEmploymentForm.values?.city === 'string') {
    partiesEmploymentForm.values.cityId = getCityIdFromCityName(partiesEmploymentForm.values?.city);
  }

  const requestBody = {
    ptsCitationId,
    tempPartyId: partiesPersonForm.tempPartyId,
    ptsPersonId: partiesPersonForm.ptsPersonId,
    ptsIncPersonId: partiesPersonForm.ptsCitationPersonId,
    partyType: partiesPersonForm.values.partyType,
    personDetail: partiesPersonForm,
    contactDetail: partiesContactForm,
    employmentDetail: partiesEmploymentForm,
    otherDetail: partiesOthersForm,
    user: user,
  };

  let response;
  if (partiesPersonForm.ptsCitationPersonId) {
    response = await service.patch(null, requestBody);
  } else {
    response = await service.create(requestBody);
  }

  return response;
};

export const upsertCitLocation = async (ptsCitationId) => {
  const incidentState = store.store.getState().incident;
  let { addressForm, addressIdForm, coordinatesForm } = incidentState;
  const user = store.store.getState().user.userData?.user.Username;
  const client = store.store.getState().websocket.websocket;

  if (!addressForm) return;

  if (!client) {
    return;
  }

  if (typeof addressForm.values?.city === 'string') {
    addressForm.values.cityId = getCityIdFromCityName(addressForm.values?.city);
  }

  if (Object.keys(addressForm.values).length <= 1) return;

  const addressRequestBody = {
    ptsCitationId: ptsCitationId,
    ptsAddressId: addressForm?.ptsAddressId,
    addressDetails: addressForm,
    addressIdDetails: addressIdForm,
    addressCoordinatesDetails: coordinatesForm,
    user: user,
  };

  const service = client.service('citation-address');

  let response;
  if (addressForm?.values?.ptsCitationLocationID) {
    response = await service.patch(addressForm?.ptsAddressId, addressRequestBody);
  } else {
    response = await service.create(addressRequestBody);
  }

  return response;
};

//TODO: Will refactor
export const upsertCitVehicle = async (ptsCitationId) => {
  const incidentState = store.store.getState().incident;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  const { propertyVehicleDetailsForm, propertyCredentialsAnalysisForm } = incidentState;
  const { attachments } = propertyVehicleDetailsForm.values;

  try {
    let vehicleRegistrationData = {};
    let vehicleDetailsData = {};
    let newEntityitem, data;

    vehicleDetailsData = {
      VIN: propertyCredentialsAnalysisForm?.values?.vin || '',
      Make: propertyVehicleDetailsForm?.values?.make || '',
      Model: propertyVehicleDetailsForm?.values?.model || '',
      Style: propertyVehicleDetailsForm?.values?.style || '',
      Year: propertyVehicleDetailsForm?.values?.year || '',
      PrimaryColor: propertyVehicleDetailsForm?.values?.primaryColor || '',
      IsTowed: propertyVehicleDetailsForm?.values?.isTowed || false,
      Brand: propertyVehicleDetailsForm?.values?.brand || '',
      Brander: propertyVehicleDetailsForm?.values?.brander || '',
      CommercialID: propertyCredentialsAnalysisForm?.values?.commercialId || '',
      Commercial: propertyCredentialsAnalysisForm?.values?.commercialCode || '',
      IsCMVIndicator: propertyCredentialsAnalysisForm?.values?.cmv || '',
      PropertyID: propertyCredentialsAnalysisForm?.values?.propertyId || '',
      Property: propertyCredentialsAnalysisForm?.values?.propertyCode || '',
      EmissionInspectionQty: propertyCredentialsAnalysisForm?.values?.emissionInspection || '',
      GarageFacility: propertyCredentialsAnalysisForm?.values?.garageFacility || '',
      GarageIndicator: propertyCredentialsAnalysisForm?.values?.garageIndicator || '',
      VINAnalysisText: propertyCredentialsAnalysisForm?.values?.vinAnalysisTest || '',
      IsRented: propertyCredentialsAnalysisForm?.values?.rented || false,
      Classification: propertyVehicleDetailsForm?.values?.classification || '',
      SecondaryColor: propertyCredentialsAnalysisForm?.values?.secondaryColor || '',
      InteriorColor: propertyCredentialsAnalysisForm?.values?.interior || '',
      PassengerSafeQty: propertyCredentialsAnalysisForm?.values?.passengerQuantity || '',
      SeatQty: propertyCredentialsAnalysisForm?.values?.seatQuantity || '',
      Transmission: propertyCredentialsAnalysisForm?.values?.transmissionCode || '',
      IsWanted: propertyCredentialsAnalysisForm?.values?.wanted || false,
      Description: propertyVehicleDetailsForm?.values?.description || '',
    };

    if (propertyVehicleDetailsForm.ptsCitationVehicleID) {
      newEntityitem = await patchCitEntity(
        propertyVehicleDetailsForm.ptsVehicleID,
        { ...vehicleDetailsData, UpdatedBy: user },
        'ptsvehicle'
      );

      vehicleRegistrationData = {
        ptsVehicleID: newEntityitem.ptsVehicleID,
        ptsParentID: ptsCitationId,
        PartyType: 'Citation',
        PlateType: propertyCredentialsAnalysisForm?.values.plateType || '',
        PlateColor: propertyCredentialsAnalysisForm?.values.plateColor || '',
        PlateNumber: propertyCredentialsAnalysisForm?.values.plateNumber || '',
        PlateState: propertyCredentialsAnalysisForm?.values.plateState || '',
        PlateExpiration: propertyCredentialsAnalysisForm?.values.plateExpiration,
        PlateStatus: propertyCredentialsAnalysisForm?.values.plateStatus || '',
        PlateDetail: propertyCredentialsAnalysisForm?.values.plateDetail || '',
        PlateBackgroundText: propertyCredentialsAnalysisForm?.values.plateBackgroundText || '',
        UpdatedBy: user,
      };

      const service = client.service('ptsvehiclereg');
      service.timeout = 20000;
      await service
        .find({
          query: {
            $select: ['ptsRegistrationID'],
            ptsVehicleID: propertyVehicleDetailsForm.values.ptsVehicleID,
            IsDeleted: false,
          },
        })
        .then(async (vehicleReg) => {
          const { ptsRegistrationID } = vehicleReg.data[0];
          await patchCitEntity(ptsRegistrationID, vehicleRegistrationData, 'ptsvehiclereg');
        });

      data = {
        VIN: propertyCredentialsAnalysisForm?.values?.vin || '',
        LicensePlate: propertyCredentialsAnalysisForm?.values.plateNumber || '',
        LicensePlateExpiration: propertyCredentialsAnalysisForm?.values.plateExpiration,
        LicenseState: propertyCredentialsAnalysisForm?.values.plateState || '',
        Make: propertyVehicleDetailsForm?.values?.make || '',
        Model: propertyVehicleDetailsForm?.values?.model || '',
        Style: propertyVehicleDetailsForm?.values?.style || '',
        Year: propertyVehicleDetailsForm?.values?.year || '',
        Color: propertyVehicleDetailsForm?.values?.primaryColor || '',
        IsTowed: propertyVehicleDetailsForm?.values?.isTowed || false,
        TowedBy: propertyVehicleDetailsForm?.values?.towedBy || false,
        IsSeatBeltInUse: propertyVehicleDetailsForm?.values?.isSeatBeltInUse || false,
        IsHazard: propertyVehicleDetailsForm?.values?.isHazard || false,
        IsCommercial: propertyVehicleDetailsForm?.values?.isCommercial || false,
        IsAccident: propertyVehicleDetailsForm?.values?.IsAccident || false,
        Classification: propertyVehicleDetailsForm?.values?.classification || '',
        Brand: propertyVehicleDetailsForm?.values?.brand || '',
        Brander: propertyVehicleDetailsForm?.values?.brander || '',
        VINAnalysisText: propertyCredentialsAnalysisForm?.values?.vinAnalysisTest || '',
        PropertyID: propertyCredentialsAnalysisForm?.values?.propertyId || '',
        Property: propertyCredentialsAnalysisForm?.values?.propertyCode || '',
        CommercialID: propertyCredentialsAnalysisForm?.values?.commercialId || '',
        Commercial: propertyCredentialsAnalysisForm?.values?.commercialCode || '',
        IsCMVIndicator: propertyCredentialsAnalysisForm?.values?.cmv || '',
        EmissionInspectionQty: propertyCredentialsAnalysisForm?.values?.emissionInspection || '',
        Transmission: propertyCredentialsAnalysisForm?.values?.transmissionCode || '',
        PlateType: propertyCredentialsAnalysisForm?.values.plateType || '',
        PlateColor: propertyCredentialsAnalysisForm?.values.plateColor || '',
        PlateStatus: propertyCredentialsAnalysisForm?.values.plateStatus || '',
        PlateDetail: propertyCredentialsAnalysisForm?.values.plateDetail || '',
        PlateBackgroundText: propertyCredentialsAnalysisForm?.values.plateBackgroundText || '',
        IsRented: propertyCredentialsAnalysisForm?.values?.rented || false,
        IsWanted: propertyCredentialsAnalysisForm?.values?.wanted || false,
        GarageIndicator: propertyCredentialsAnalysisForm?.values?.garageIndicator || '',
        GarageFacility: propertyCredentialsAnalysisForm?.values?.garageFacility || '',
        PassengerSafeQty: propertyCredentialsAnalysisForm?.values?.passengerQuantity || '',
        SeatQty: propertyCredentialsAnalysisForm?.values?.seatQuantity || '',
        SecondaryColor: propertyCredentialsAnalysisForm?.values?.secondaryColor || '',
        InteriorColor: propertyCredentialsAnalysisForm?.values?.interior || '',
        Description: propertyVehicleDetailsForm?.values?.description || '',
        ptsCitationID: ptsCitationId,
        ptsVehicleID: newEntityitem.ptsVehicleID,
        UpdatedBy: user,
      };

      newEntityitem = await patchCitEntity(
        propertyVehicleDetailsForm.ptsCitationVehicleID,
        data,
        'citcitationvehicles'
      );

      if (attachments && attachments.length > 0) {
        const attachmentService = client.service('rms-attachments');
        attachmentService.timeout = 20000;

        attachments.map(async (f) => {
          if (!f.ptsAttachmentId) {
            await attachmentService.create({
              ptsParentID: propertyVehicleDetailsForm.ptsCitationVehicleID,
              parentType: 'CITATIONVEHICLE',
              file: f.file,
              description: f.description,
              fileName: f.name,
              fileType: f.type,
              IsLocked: false,
              IsSealed: false,
              IsDeleted: false,
              UpdatedBy: user,
              CreatedBy: user,
            });
          }
        });
      }

      return newEntityitem;
    } else {
      newEntityitem = await createCitEntity(
        { ...vehicleDetailsData, CreatedBy: user, UpdatedBy: user },
        'ptsvehicle'
      );

      vehicleRegistrationData = {
        ptsVehicleID: newEntityitem.ptsVehicleID,
        ptsParentID: ptsCitationId,
        PartyType: 'Citation',
        PlateType: propertyCredentialsAnalysisForm?.values.plateType || '',
        PlateColor: propertyCredentialsAnalysisForm?.values.plateColor || '',
        PlateNumber: propertyCredentialsAnalysisForm?.values.plateNumber || '',
        PlateState: propertyCredentialsAnalysisForm?.values.plateState || '',
        PlateExpiration: propertyCredentialsAnalysisForm?.values.plateExpiration,
        PlateStatus: propertyCredentialsAnalysisForm?.values.plateStatus || '',
        PlateDetail: propertyCredentialsAnalysisForm?.values.plateDetail || '',
        PlateBackgroundText: propertyCredentialsAnalysisForm?.values.plateBackgroundText || '',
        CreatedBy: user,
        UpdatedBy: user,
      };

      await createCitEntity(vehicleRegistrationData, 'ptsvehiclereg');

      data = {
        VIN: propertyCredentialsAnalysisForm?.values?.vin || '',
        LicensePlate: propertyCredentialsAnalysisForm?.values.plateNumber || '',
        LicensePlateExpiration: propertyCredentialsAnalysisForm?.values.plateExpiration,
        LicenseState: propertyCredentialsAnalysisForm?.values.plateState || '',
        Make: propertyVehicleDetailsForm?.values?.make || '',
        Model: propertyVehicleDetailsForm?.values?.model || '',
        Style: propertyVehicleDetailsForm?.values?.style || '',
        Year: propertyVehicleDetailsForm?.values?.year || '',
        Color: propertyVehicleDetailsForm?.values?.primaryColor || '',
        IsTowed: propertyVehicleDetailsForm?.values?.isTowed || false,
        TowedBy: propertyVehicleDetailsForm?.values?.towedBy || false,
        IsSeatBeltInUse: propertyVehicleDetailsForm?.values?.isSeatBeltInUse || false,
        IsHazard: propertyVehicleDetailsForm?.values?.isHazard || false,
        IsCommercial: propertyVehicleDetailsForm?.values?.isCommercial || false,
        IsAccident: propertyVehicleDetailsForm?.values?.isAccIsAccident || false,
        Classification: propertyVehicleDetailsForm?.values?.classification || '',
        Brand: propertyVehicleDetailsForm?.values?.brand || '',
        Brander: propertyVehicleDetailsForm?.values?.brander || '',
        VINAnalysisText: propertyCredentialsAnalysisForm?.values?.vinAnalysisTest || '',
        PropertyID: propertyCredentialsAnalysisForm?.values?.propertyId || '',
        Property: propertyCredentialsAnalysisForm?.values?.propertyCode || '',
        CommercialID: propertyCredentialsAnalysisForm?.values?.commercialId || '',
        Commercial: propertyCredentialsAnalysisForm?.values?.commercialCode || '',
        IsCMVIndicator: propertyCredentialsAnalysisForm?.values?.cmv || '',
        EmissionInspectionQty: propertyCredentialsAnalysisForm?.values?.emissionInspection || '',
        Transmission: propertyCredentialsAnalysisForm?.values?.transmissionCode || '',
        PlateType: propertyCredentialsAnalysisForm?.values.plateType || '',
        PlateColor: propertyCredentialsAnalysisForm?.values.plateColor || '',
        PlateStatus: propertyCredentialsAnalysisForm?.values.plateStatus || '',
        PlateDetail: propertyCredentialsAnalysisForm?.values.plateDetail || '',
        PlateBackgroundText: propertyCredentialsAnalysisForm?.values.plateBackgroundText || '',
        IsRented: propertyCredentialsAnalysisForm?.values?.rented || false,
        IsWanted: propertyCredentialsAnalysisForm?.values?.wanted || false,
        GarageIndicator: propertyCredentialsAnalysisForm?.values?.garageIndicator || '',
        GarageFacility: propertyCredentialsAnalysisForm?.values?.garageFacility || '',
        PassengerSafeQty: propertyCredentialsAnalysisForm?.values?.passengerQuantity || '',
        SeatQty: propertyCredentialsAnalysisForm?.values?.seatQuantity || '',
        SecondaryColor: propertyCredentialsAnalysisForm?.values?.secondaryColor || '',
        InteriorColor: propertyCredentialsAnalysisForm?.values?.interior || '',
        Description: propertyVehicleDetailsForm?.values?.description || '',
        ptsCitationID: ptsCitationId,
        ptsVehicleID: newEntityitem.ptsVehicleID,
        CreatedBy: user,
        UpdatedBy: user,
      };

      newEntityitem = await createCitEntity(data, 'citcitationvehicles');

      if (attachments && attachments.length > 0) {
        const attachmentService = client.service('rms-attachments');
        attachmentService.timeout = 20000;

        attachments.map(async (f) => {
          if (!f.ptsAttchmentId) {
            await attachmentService.create({
              ptsParentID: newEntityitem.ptsCitationVehicleID,
              parentType: 'CITATIONVEHICLE',
              file: f.file,
              description: f.description,
              fileName: f.name,
              fileType: f.type,
              IsLocked: false,
              IsSealed: false,
              IsDeleted: false,
              UpdatedBy: user,
              CreatedBy: user,
            });
          }
        });
      }

      return newEntityitem;
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeCitEntity = async (entity, data) => {
  const wsClient = store.store.getState().websocket.websocket;
  const definition = {
    citcitations: {
      service: 'citcitations',
      datbaseKey: 'ptsCitationID',
    },
    citcitationlocations: {
      service: 'citcitationlocations',
      datbaseKey: 'ptsCitationLocationID',
    },
    ptstags: {
      service: 'ptstags',
      datbaseKey: 'ptsTagID',
    },
    citcitationpersons: {
      service: 'citcitationpersons',
      databaseKey: 'ptsCitationPersonID',
    },
    citcitationvehicles: {
      service: 'citcitationvehicles',
      databaseKey: 'ptsCitationVehicleID',
    },
    citcitationviolations: {
      service: 'citcitationviolations',
      databaseKey: 'ptsCitationViolationID',
    },
    ptscomments: {
      service: 'ptscomments',
      databaseKey: 'ptsCommentID',
    },
    'rms-attachments': {
      service: 'rms-attachments',
      databaseKey: 'ptsAttachmentID',
    },
  };

  try {
    const serviceName = definition[entity].service;
    const service = wsClient.service(serviceName);
    service.timeout = 20000;

    await service.remove(data);
  } catch (error) {
    console.log(error);
  }
};
