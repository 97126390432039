import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Button, makeStyles, TextField, Box } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { gridStyle, useError } from 'utils/formStyles';
import { formatSaveData } from 'utils/formStyles';

import Dialog from 'components/RMSDialog';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import TextField2 from 'components/RMSTextField';
import { SSNTextMask } from 'components/RMSTextMask';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { closePeopleCredentials } from 'reducers/PersonDialogsReducer';
import { getService } from 'reducers/service';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  item: gridStyle(300, 500),
  txtField: {
    minWidth: 350,
  },
  closeButton: { color: theme.button.color },
}));

function PeopleCredentialsDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const [Type, setType] = useState('');
  const [Number, setNumber] = useState('');
  const [State, setState] = useState(null);
  const [errors, setErrors] = useState();
  const [ssnError, setSsnError] = useState(null);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const dispatch = useDispatch();
  const { err, delErr, isValidAndChanged, formChanged } = useError(errors, setErrors);

  const userName = useSelector((state) => state.user.userData?.user.Username);

  useEffect(() => {
    if (data.ptsCredentialID) {
      const { ptsCredentialID, Type, ptsPersonID, Number, State } = data;

      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Created,
        createUpdateInfo: {
          created: data.Created,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });

      setType(Type);
      setNumber(Number);
      setState(State);
    }
  }, []);

  useEffect(() => {
    /* Check if SSN is valid or not */
    if (Type?.Code === 'SSN' && (Number || Number === '')) {
      const { firstPart, secondPart, thirdPart } = splitSSN(Number);
      const toCheckValue = parseInt(firstPart);
      const ssnNumberCheck = Number.replace(/-/g, '');

      if (ssnNumberCheck && ssnNumberCheck.length < 9)
        setSsnError('SSN value can not be less than 9 characters.');
      else if (toCheckValue > 740)
        setSsnError('First three digits of an SSN cannot be greater than 740.');
      else if (firstPart === '000' || secondPart === '00' || thirdPart === '0000')
        setSsnError('None of the three parts of an SSN can be all zeros.');
      else setSsnError(null);
    } else setSsnError(null);
  }, [Number, Type]);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const splitSSN = (value) => {
    let firstPart = '';
    let secondPart = '';
    let thirdPart = '';

    if (value === '   -  -    ' || value === '') {
      return { firstPart: 0, secondPart, thirdPart };
    }

    for (let i of value) {
      if (i !== '-') {
        if (firstPart.length < 3) {
          firstPart += i;
        } else {
          if (secondPart.length < 2) {
            secondPart += i;
          } else {
            if (thirdPart.length < 4) {
              thirdPart += i;
            }
          }
        }
      }
    }

    return { firstPart, secondPart, thirdPart };
  };

  const close = () => {
    dispatch(closePeopleCredentials());
  };

  const save = async () => {
    const formData = formatSaveData({ Type, Number, State });
    try {
      props.showSpinner();
      const service = getService('record-person-credentials');
      if (data.ptsPersonID && data.ptsCredentialID) {
        const res = await service.patch(data.ptsCredentialID, { ...formData, UpdatedBy: userName });
        dispatch(notify('Credential updated', 'success'));
      } else {
        const res = await service.create({
          ...formData,
          ptsPersonID: data.ptsPersonID,
          CreatedBy: userName,
        });
        dispatch(notify('Credential Created', 'success'));
      }
    } catch (err) {
      props.handleError(err);
    } finally {
      close();
      props.hideSpinner();
    }
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged() || ssnError}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderType = () => {
    const onChange = (ev, val) => {
      setType(val);
      formChanged();
    };
    const error = err(Type, 'Type');
    return (
      <Dictionary
        className={classes.item}
        options="codeCredentialTypes"
        onChange={onChange}
        formType={data.ptsCredentialID ? 'edit' : 'add'}
        value={Type}
        label="Type"
        error={error}
        compact
      />
    );
  };

  const renderSSNInfo = () => {
    const onChange = (e) => {
      setNumber(e.target.value);
      formChanged();
    };
    const error = err(Number, 'Credential Number');
    return (
      <TextField
        className={classes.item}
        label="Info"
        value={Number}
        onChange={onChange}
        max={100}
        error={error || ssnError}
        helperText={ssnError}
        disabled={!Type}
        compact
        variant="outlined"
        size="small"
        InputProps={{
          inputComponent: SSNTextMask,
        }}
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setNumber(val);
      formChanged();
    };
    const error = err(Number, 'Credential Number');
    return (
      <TextField2
        className={classes.item}
        label="Info"
        value={Number}
        onChange={onChange}
        max={100}
        error={error}
        disabled={!Type}
        compact
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => {
      formChanged();
      setState(val);
    };

    return (
      <Dictionary
        options="codeStates"
        className={classes.item}
        onChange={onChange}
        formType={data.ptsCredentialID ? 'edit' : 'add'}
        value={State}
        label="State"
        compact
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit Credential" actions={renderActions()}>
      {renderType()}
      {Type?.Code === 'SSN' ? renderSSNInfo() : renderInfo()}
      {renderState()}
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(PeopleCredentialsDialog);
