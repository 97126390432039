export const ADD_SHARE_MODAL_WORKFLOW = 'WORKFLOW/ADD_SHARE_MODAL_WORKFLOW';
export const SET_WORKFLOWS = 'WORKFLOW/SET_WORKFLOWS';
export const SET_STEPS = 'WORKFLOW/SET_STEPS';
export const SET_CURRENT_STEP_VALUE = 'WORKFLOW/SET_CURRENT_STEP_VALUE';

export default function reducer(
  state = {
    currentIncidentId: 0,
    workflows: [],
    steps: [],
    persons: [],
    currentStepValue: null,
    savedSharedWorkflow: {},
  },
  action
) {
  switch (action.type) {
    case ADD_SHARE_MODAL_WORKFLOW:
      return { ...state, savedSharedWorkflow: { ...action.payload } };
    case SET_WORKFLOWS:
      return { ...state, workflows: [...action.payload] };
    case SET_STEPS:
      return { ...state, steps: [...action.payload] };
    case SET_CURRENT_STEP_VALUE:
      return { ...state, currentStepValue: action.payload };

    default:
      break;
  }
  return state;
}

export const addShareModalWorkflow = (value) => {
  return (dispatch) => {
    dispatch({ type: ADD_SHARE_MODAL_WORKFLOW, payload: value });
  };
};

export const setWorkflows = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_WORKFLOWS, payload: value });
  };
};

export const setSteps = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_STEPS, payload: value });
  };
};

export const setCurrentStepValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_STEP_VALUE, payload: value });
  };
};
