import React, { useEffect } from 'react';

import printJS from 'print-js-updated';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getService } from 'reducers/service';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      '& > div': {
        padding: 0,
      },
    },
  },
  reportWrap: {
    maxWidth: '100%',
    width: 1500,
    minHeight: 'calc(100vh - 140px)',
    '& > object': {
      width: '100%',
      border: 'none',
      height: 'calc(100vh - 140px)',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Ensure it takes up the full width of the viewport
    height: '100vh', // Ensure it takes up the full height of the viewport
    position: 'fixed', // Fixed position to cover the entire viewport
    top: 0,
    left: 0,
    zIndex: 1000,
    pointerEvents: 'none',
  },
}));

function ExampleWindow(props) {
  const classes = useStyles();
  const { report, open, onClose } = props;

  useEffect(() => {
    if (!report) return;
    const { id, data, reportList } = report;
    if (!id || !data) return;
    getReportData(id, data, reportList);
  }, [report]);

  const getReportData = async (id, data, reportList) => {
    try {
      const reportData = await getReport(id, data);
      printJS({
        printable: reportData,
        type: 'pdf',
        base64: true,
        onPrintDialogClose: () => onClose(id, reportList),
      });
    } catch (err) {
      console.log('err ', err);
    }
  };

  const getReport = async (reportId, data) => {
    const service = getService('ssrs');
    const response = await service.get(reportId, {
      query: { data },
    });

    return response;
  };

  const renderLoader = () => (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  return renderLoader();
}

export default ExampleWindow;
