import { formatSaveDateFrom, formatSaveDateTo } from 'reducers/TimeReducer';

export const processParameters = (ReportID, formData, format = 'report') => {
  switch (ReportID) {
    case '274':
      return processCitationReportData(formData, format);
    case '275':
      return processWarrantReportData(formData, format);
    case '276':
    case '277':
      return processIncidentByLocationData(formData, format);
    case '278':
      return processIncidentSeverityByZonesData(formData, format);
    case '279':
    case '280':
      return processOffenseChanreByStateData(formData, format);
    case '281':
    case '282':
      return processOfficerActivityReportData(formData, format);
    default:
      return {};
  }
};

const processCitationReportData = (formData, format) => {
  if (!formData) return {};
  // console.log('formdata', formData);
  const newForm = {
    citationDateFrom: formatDate(formData.citationDateFrom, 'from', format),
    citationDateTo: formatDate(formData.citationDateTo, 'to', format),
    parties: extractListWithId(formData.parties, 'ptsPersonID'),
    partyTypes: extractListWithId(formData.partyTypes, 'Code'),
    citationTypes: extractListWithId(formData.citationTypes, 'Code'),
    Offenses: extractListWithId(formData.offenses, 'ptsStatuteID'),
  };
  return newForm;
};

const processWarrantReportData = (formData, format) => {
  if (!formData) return {};

  const newForm = {
    issuedDateFrom: formatDate(formData.issuedDateFrom, 'from', format),
    issuedDateTo: formatDate(formData.issuedDateTo, 'to', format),
    offenseSeverities: extractListWithId(formData.offenseSeverities, 'Code'),
    heldBy: extractListWithId(formData.heldBy, 'ptsPlaceID'),
    dispositions: extractListWithId(formData.dispositions, 'Code'),
    parties: extractListWithId(formData.parties, 'ptsPersonID'),
    partyTypes: extractListWithId(formData.partyTypes, 'Code'),
    Offenses: extractListWithId(formData.offenses, 'ptsStatuteID'),
  };
  return newForm;
};
const processIncidentByLocationData = (formData, format) => {
  if (!formData) return {};

  const newForm = {
    createdDateFrom: formatDate(formData.receivedFromBeginning, 'from', format),
    createdDateTo: formatDate(formData.receivedFromEnding, 'to', format),
    locationCategories: extractListWithId(formData.locationCategories, 'Code'),
    zones: extractListWithId(formData.zones, 'ZoneCode'),
    topRows: formData.topRows,
  };
  return newForm;
};
const processIncidentSeverityByZonesData = (formData, format) => {
  if (!formData) return {};

  const newForm = {
    occurredFromBeginning: formatDate(formData.occurredFromBeginning, 'from', format),
    occurredFromEnding: formatDate(formData.occurredFromEnding, 'to', format),
    offenseSeverities: extractListWithId(formData.offenseSeverities, 'Code'),
    Offenses: extractListWithId(formData.offenses, 'ptsStatuteID'),
  };
  return newForm;
};
const processOffenseChanreByStateData = (formData, format) => {
  if (!formData) return {};
  const newForm = {
    offenseDateFrom: formatDate(formData.offenseDateFrom, 'from', format),
    offenseDateTo: formatDate(formData.offenseDateTo, 'to', format),
    arrestDateFrom: formatDate(formData.arrestDateFrom, 'from', format),
    arrestDateTo: formatDate(formData.arrestDateTo, 'to', format),
    locationCategories: extractListWithId(formData.locationCategories, 'Code'),
    zones: extractListWithId(formData.zones, 'ZoneCode'),
    Offenses: extractListWithId(formData.offenses, 'ptsStatuteID'),
    offenderHomeStates: extractListWithId(formData.offenderHomeStates, 'Code'),
  };
  return newForm;
};

const processOfficerActivityReportData = (formData, format) => {
  if (!formData) return {};

  const newForm = {
    dateFrom: formatDate(formData.dateFrom, 'from', format),
    dateTo: formatDate(formData.dateTo, 'to', format),
    officers: extractListWithId(formData.officers, 'ptsPersonID'),
    Offenses: extractListWithId(formData.offenses, 'ptsStatuteID'),
  };
  return newForm;
};
const extractListWithId = (arr = [], filedName) => {
  const newArr = arr.map((item) => item[filedName]);
  return newArr;
};

const formatDate = (data, type, format) => {
  let formatedDate = '';
  if (type === 'from') {
    formatedDate = formatSaveDateFrom(data);
  } else {
    formatedDate = formatSaveDateTo(data);
  }
  return formatedDate ? formatedDate.split(' ')[0] : format == 'csv' ? null : '';
};

export const reportNameMapper = (id) => {
  switch (id) {
    case '274':
      return 'Citation Report';
    case '275':
      return 'Warrant Report';
    case '276':
      return 'Incident By Location Summary';
    case '277':
      return 'Incident By Location Details';
    default:
      return 'Report';
  }
};
