import React, { useEffect, useState } from 'react';
import { Card, Chip, Fade, Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import { alpha } from '@mui/material';
import { getService } from 'reducers/service';
import { connect, useSelector } from 'react-redux';
import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import { showAddAdditionalOffense } from 'reducers/PersonDialogsReducer';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import AdditionalOffenseDialog from './AdditionalOffenseDialog';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';

const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '28px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    gap: '10px',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',
  },
  listItem_root: {
    padding: '0px',
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function WarrantOffenses(props) {
  const { ptsWarrantID, defaultOffense } = props;
  const service = getService('rms-warrant');
  const classes = useStyles();
  const [cardExpanded, setCardExpanded] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [rows, setRows] = useState([]);
  const [allOffenses, setAllOffenses] = useState([]);
  const [cardHeight, setCardHeight] = useState(0);
  const [selected, setSelected] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [defaultOffenseStatute, setDefaultOffenseStatute] = useState([]);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const [type, setType] = useState('add');

  const dataUpdate = useSelector((state) => state.dataUpdate);

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + 1,
      };
    });
  };
  const getData = () => {
    service
      .find({ query: { type: 'warrant_additional_offense', data: { ptsWarrantID: ptsWarrantID } } })
      .then((res) => {
        setRows(processData(res));
        //setLoaded(true);
      });
  };
  useEffect(() => {
    if ((dataUpdate && dataUpdate.type === 'warrants-offenses') || initialLoad) {
      getData();
    }
    setInitialLoad(false);
  }, [dataUpdate, defaultOffenseStatute]);

  useEffect(() => {
    if (allOffenses.length > 0) {
      if (cardExpanded) {
        setCardHeight(45 * allOffenses.length + 8 + 'px');
      } else {
        setCardHeight('90px');
      }
    }
  }, [cardExpanded, allOffenses]);

  useEffect(() => {
    if (defaultOffenseStatute) {
      setAllOffenses([defaultOffenseStatute, ...rows]);
    } else {
      setAllOffenses(rows);
    }
  }, [rows, defaultOffenseStatute]);

  useEffect(() => {
    if (!defaultOffense) return;
    const offenseStatute = ptsStatute.find((item) => item.ptsStatuteID === defaultOffense);

    setDefaultOffenseStatute({
      ...offenseStatute,
      primary: true,
      StatuteDescription: offenseStatute?.Description,
      id: 'primary',
    });
  }, [defaultOffense, rows]);

  const generateHeaderRow = () => {
    return (
      <Grid container item lg={12} xs={12} className={classes.listHeader}>
        <Grid container item xs={10}>
          <Grid item xs={1}></Grid>
          <Grid item lg={2} xs={3}>
            Statute Code
          </Grid>
          <Grid item xs={7} lg={9}>
            Description
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    );
  };

  const generateListItem = (it) => {
    return (
      <Grid container item xs={10} className="items-center">
        <Grid item xs={1}></Grid>
        <Grid container item lg={2} xs={3} className={classes.parent_div}>
          <div className={classes.default_div}>{it.Code}</div>
        </Grid>
        <Grid container item xs={8} lg={9} className={classes.parent_div}>
          <div className={classes.default_div}>
            {it.StatuteDescription || ''}
            {it.primary && (
              <Chip
                label="Primary"
                variant="outlined"
                size="small"
                color="primary"
                alignItems="center"
              />
            )}
          </div>
        </Grid>
      </Grid>
    );
  };

  const onAdd = () => {
    setType('add');
    setAddForm(true);
  };

  const onDelete = async (row) => {
    try {
      props.showSpinner();

      await service
        .patch(ptsWarrantID, {
          type: 'remove_warrant_additional_offense',
          data: {
            ptsWarrantID,
            ptsStatuteID: row?.ptsStatuteID,
          },
        })
        .then((res) => {
          getData();
        });
      // props.notifyDataUpdate({ type: 'Warrant Offenses' });
      //props.notify('Warrant Offenses Deleted', 'warning');
      setIsDeleted(!isDeleted);
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const onEditPrimaryOffense = (row) => {
    setType('edit');
    setAddForm(true);
  };

  return (
    <Card className={classes.cardView}>
      <Grid container spacing={1}>
        <Grid item container style={{ marginLeft: '10px', marginBottom: '3px' }} xs={12}>
          <Grid item xs={12} style={{ marginTop: '6px', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px' }}></span>
            <LibraryBooksRoundedIcon style={{ color: PRIMARY_COLOR }} />
            <Typography variant="span" className={classes.spanHeader}>
              Offenses {allOffenses.length > 0 ? `(${allOffenses.length})` : ''}
            </Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                paddingBottom: '3px',
                paddingTop: '3px',
              }}>
              <span
                style={{
                  padding: '0px',
                  '&:hover': {
                    background: 'transparent',
                  },
                  border: 'none',
                  background: 'transparent',
                  marginLeft: '-7px',
                }}>
                {!addForm && <PlusCircleButton disabled={props.viewOnly} onClick={() => onAdd()} />}
              </span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownCircleIcon
                  onClick={() => setCardExpanded(!cardExpanded)}
                  className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </div>
            </span>
          </Grid>
          {addForm && (
            <Grid
              container
              item
              xs={12}
              style={{
                marginTop: '6px',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '30px',
              }}>
              <AdditionalOffenseDialog
                ptsWarrantID={ptsWarrantID}
                setAddForm={setAddForm}
                type={type}
                viewOnly={props.viewOnly}
                primaryOffense={defaultOffenseStatute}
              />
            </Grid>
          )}
          {allOffenses.length > 0 && (
            <Grid container item xs={12} className={classes.list_div}>
              {generateHeaderRow()}
              <List className={classes.list_root} style={{ height: cardHeight }}>
                {allOffenses.map((it, index) => {
                  return (
                    <ListItem className={classes.listItem_root}>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ borderWidth: index === 0 ? '0' : '' }}
                        className={classes.listItem}
                        onMouseEnter={(event) => setSelected(it)}
                        onMouseLeave={() => setSelected({})}>
                        {generateListItem(it)}
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingTop: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Fade in={selected.id === it.id && it.id === 'primary'}>
                            <Grid container wrap="nowrap" spacing={2} justify="flex-end" xs={12}>
                              <Grid item justify="flex-end">
                                <RMSEditButton
                                  className={classes.fabStyle}
                                  viewOnly={props.viewOnly}
                                  onClick={() => onEditPrimaryOffense(it)}
                                />
                              </Grid>
                            </Grid>
                          </Fade>
                          {selected.id === it.id && it.id !== 'primary' ? (
                            <Fade in={true}>
                              <Grid container wrap="nowrap" spacing={2} justify="flex-end" xs={12}>
                                <Grid item justify="flex-end">
                                  <RMSDeleteButton
                                    className={classes.fabStyle}
                                    onClick={() => onDelete(it)}
                                    entityName={'Offense'}
                                    disabled={props.viewOnly}
                                  />
                                </Grid>
                              </Grid>
                            </Fade>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default connect(null, {
  showAddAdditionalOffense,
  showSpinner,
  hideSpinner,
  notify,
  handleError,
})(WarrantOffenses);
