import React from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PlaceLookup from 'components/RMSPlaceLookup';
import { alpha } from '@mui/material';

import { connect, useSelector } from 'react-redux';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';

import { Gavel } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Row, gridStyle } from 'utils/formStyles';
import { formatSaveDate } from 'reducers/TimeReducer';
import clsx from 'clsx';

const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '28px',
    marginRight: '5px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',
  },
  listItem_root: {
    padding: '0px',
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  item2: gridStyle('100%', 800),
  rightPadding: {
    paddingRight: '10px',
  },
}));

function WarrantInformation(props) {
  const { warrant, onChange } = props;
  const classes = useStyles();

  const dictionary = useSelector((state) => state.dictionary);
  const { codeZones, Citation_Court } = dictionary;

  const renderHeldBy = () => {
    return (
      <PlaceLookup
        // options="codeHeldBy"
        className={classes.item2}
        onChange={(value) => onChange('HeldByID', value?.ptsPlaceID)}
        ptsPlaceID={warrant.HeldByID}
        label="Held By"
      />
    );
  };

  const renderServiceDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Service Date"
          value={warrant.ServedByDate}
          onChange={(date) => onChange('ServedByDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderExpirationDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Expiration Date"
          value={warrant.ExpirationDate}
          onChange={(date) => onChange('ExpirationDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderIncidentID = () => {
    return (
      <TextField
        label="Incident ID"
        variant="outlined"
        onChange={(e) => {
          onChange('IncidentID', e.target.value);
        }}
        value={warrant.IncidentID}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderZone = () => {
    const value = codeZones.find((item) => item.ZoneCode === warrant.Zone) || null;
    return (
      <RMSDictionary
        options="codeZones"
        className={classes.item2}
        onChange={(e, value) => onChange('Zone', value?.ZoneCode)}
        value={value}
        label="Zone"
      />
    );
  };

  const renderCourt = () => {
    const value = Citation_Court.find((item) => item.Court_Name === warrant.Court) || null;
    return (
      <RMSDictionary
        options="Citation_Court"
        value={value}
        className={classes.item2}
        onChange={(e, value) => onChange('Court', value?.Court_Name)}
        label="Court"
      />
    );
  };

  const renderWarrantID = () => {
    return (
      <TextField
        label="User Warrant ID"
        variant="outlined"
        onChange={(e) => {
          onChange('UserWarrantID', e.target.value);
        }}
        value={warrant.UserWarrantID}
        className={clsx(classes.item2, classes.rightPadding)}
        size="small"
      />
    );
  };

  const renderCourtDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Court Date"
          value={warrant.CourtDate}
          onChange={(date) => onChange('CourtDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDocketNumber = () => {
    return (
      <TextField
        label="Docket Number"
        variant="outlined"
        onChange={(e) => {
          onChange('DocketNumber', e.target.value);
        }}
        value={warrant.DocketNumber}
        className={classes.item2}
        type="text"
        size="small"
      />
    );
  };

  const renderFineAmount = () => {
    return (
      <TextField
        label="Fine Amount"
        variant="outlined"
        onChange={(e) => {
          onChange('FineAmount', e.target.value);
        }}
        value={warrant.FineAmount}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderAmountPaid = () => {
    return (
      <TextField
        label="Amount Paid"
        variant="outlined"
        onChange={(e) => {
          onChange('AmountPaid', e.target.value);
        }}
        value={warrant.AmountPaid}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderCountQty = () => {
    return (
      <TextField
        label="Offense Count"
        variant="outlined"
        onChange={(e) => {
          onChange('CountQty', e.target.value);
        }}
        value={warrant.CountQty}
        className={clsx(classes.item2, classes.rightPadding)}
        size="small"
        type="number"
        InputLabelProps={{
          shrink: warrant.CountQty ? true : false,
        }}
      />
    );
  };

  const renderArrestDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Arrest Date"
          value={warrant.ArrestDate}
          onChange={(date) => onChange('ArrestDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderPrintLabel = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.PrintLabel}
            onChange={(e) => onChange('PrintLabel', e.target.checked)}
            name="PrintLabel"
            color="primary"
          />
        }
        label="Print Label"
        className={classes.item2}
      />
    );
  };

  const renderIsOnHold = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsOnHold}
            onChange={(e) => onChange('IsOnHold', e.target.checked)}
            name="IsOnHold"
            color="primary"
          />
        }
        label="Is On Hold"
        className={classes.item2}
      />
    );
  };

  const renderIsClearedByArrestDock = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsClearedByArrestDocketNotCreated}
            onChange={(e) => onChange('IsClearedByArrestDocketNotCreated', e.target.checked)}
            name="IsClearedByArrestDocketNotCreated"
            color="primary"
          />
        }
        label="Cleared By Arrest, Docket Not Created"
        className={classes.item2}
      />
    );
  };

  const renderIsDocketCreated = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsDocketCreated}
            onChange={(e) => onChange('IsDocketCreated', e.target.checked)}
            name="IsDocketCreated"
            color="primary"
          />
        }
        label="Docket Created"
        className={classes.item2}
      />
    );
  };

  return (
    <Card className={classes.cardView}>
      <Grid container spacing={1}>
        <Grid item container style={{ marginLeft: '10px', marginBottom: '3px' }} xs={12}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '6px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}>
            <span style={{ marginRight: '5px' }}></span>
            <Gavel style={{ color: PRIMARY_COLOR }} />
            <Typography variant="span" className={classes.spanHeader}>
              Warrant Information
            </Typography>
          </Grid>

          <Row>
            <Grid
              container
              item
              xs={12}
              className={classes.list_div}
              spacing={1}
              style={{ paddingTop: '10px' }}>
              <Grid item xs={4} md={2}>
                {renderHeldBy()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderServiceDate()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderExpirationDate()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderIncidentID()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderZone()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderWarrantID()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderCourt()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderCourtDate()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderDocketNumber()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderFineAmount()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderAmountPaid()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderCountQty()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderArrestDate()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderPrintLabel()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderIsOnHold()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderIsClearedByArrestDock()}
              </Grid>
              <Grid item xs={4} md={2}>
                {renderIsDocketCreated()}
              </Grid>
            </Grid>
          </Row>
        </Grid>
      </Grid>
    </Card>
  );
}

export default connect(null, {})(WarrantInformation);
